import * as types from '~/store/mutation-types'
import { guid } from '@/plugins/helper'

export const state = () => ({
  errorMessageArray: [],
  is410Page: false,
  vueFilesValidationResults: null
})

export const getters = {
  errorMessages: state => state.errorMessageArray,
  is410Page: state => state.is410Page,
  vueFilesValidationResults: state => state.vueFilesValidationResults
}

export const actions = {
  addErrorMessageToQueue({ commit }, error) {
    const payload = {
      ...error,
      id: guid()
    }
    commit(types.ADD_ERROR_MESSAGE_TO_QUEUE, payload)
  },
  removeErrorMessage({ commit }, errorId) {
    commit(types.REMOVE_ERROR_MESSAGE_FROM_QUEUE, errorId)
  },
  clearErrorMessages({ commit }) {
    commit(types.CLEAR_ERROR_MESSAGE_QUEUE)
  },
  saveVueFilesValidationResults({ commit }, validationResults) {
    commit(types.SAVE_VUE_FILES_VALIDATION_RESULTS, validationResults)
  }
}

export const mutations = {
  [types.ADD_ERROR_MESSAGE_TO_QUEUE](state, error) {
    state.errorMessageArray = [...state.errorMessageArray, error]
  },
  [types.REMOVE_ERROR_MESSAGE_FROM_QUEUE](state, errorId) {
    state.errorMessageArray = state.errorMessageArray.filter(
      ({ id }) => errorId !== id
    )
  },
  [types.CLEAR_ERROR_MESSAGE_QUEUE](state) {
    state.errorMessageArray = []
  },
  [types.SET_CURRENT_PAGE_AS_410](state) {
    state.is410Page = true
  },
  [types.SET_CURRENT_PAGE_AS_NOT_410](state) {
    state.is410Page = false
  },
  [types.SAVE_VUE_FILES_VALIDATION_RESULTS](state, validationResults) {
    state.vueFilesValidationResults = validationResults
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
