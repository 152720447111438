import {
  isObject,
  checkIfUrlContainsDomain,
  checkIfUrlStartsWithDomain,
  removeDomainFromUrl
} from '@fmpedia/helpers'
import { REDIRECT_TYPE, REDIRECT_TYPE_CODES } from '@fmpedia/enums'

export function redirectHandler({
  ctx,
  to,
  type = REDIRECT_TYPE_CODES[REDIRECT_TYPE.MOVED_PERMANENTLY],
  debug = false
}) {
  const router = ctx?.app?.router || ctx?.$router
  const domainUrl = (ctx?.app?.$env || ctx?.$env)?.DOMAIN_URL

  if (!ctx || !to) {
    throw new Error('redirectHandler: ctx and to arguments are required!')
  }
  if (!router) {
    throw new Error(
      'redirectHandler: router cannot be found in the provided context!'
    )
  }
  if (!domainUrl) {
    throw new Error(
      'redirectHandler: DOMAIN_URL env variable cannot be found in the provided context!'
    )
  }

  function debugLog(...args) {
    if (!debug) return

    const [first, ...others] = args
    const firstArg = `redirectHandler > ${first}`

    console.log(firstArg, ...others)
  }
  function clientSideInternalRedirectHandler(path) {
    ctx.store.dispatch('router/concurrentRouterPush', {
      router: ctx.app.router,
      to: path
    })
  }
  function clientSideExternalRedirectHandler(url) {
    window.location.href = url
  }
  function serverSideRedirectHandler(redirectTo) {
    ctx.redirect(type, redirectTo)
  }

  debugLog('domainUrl: ', domainUrl)

  let redirectTo

  debugLog('to: ', to)
  if (isObject(to)) {
    const { resolved } = ctx.app.router.resolve(to)
    redirectTo = resolved.fullPath
  } else {
    redirectTo = to
  }
  debugLog('redirectTo: ', redirectTo)

  if (process.client) {
    debugLog('Client-side')
    const isExternal =
      checkIfUrlContainsDomain(redirectTo) &&
      !checkIfUrlStartsWithDomain(redirectTo, domainUrl)
    debugLog('isExternal: ', isExternal)

    if (isExternal) {
      debugLog('Redirect with clientSideExternalRedirectHandler: ', redirectTo)
      clientSideExternalRedirectHandler(redirectTo)
      return
    }

    const path = removeDomainFromUrl(redirectTo, domainUrl)
    debugLog('path: ', path)

    debugLog('Redirect with clientSideInternalRedirectHandler: ', path)
    clientSideInternalRedirectHandler(path)
  } else {
    debugLog('Server-side')
    debugLog('Redirect with serverSideRedirectHandler: ', redirectTo)
    serverSideRedirectHandler(redirectTo)
  }
}
