import { pipe } from 'ramda'

import { MIDDLEWARE } from 'enums/middleware'
import { REDIRECT_TYPE_CODES } from '@fmpedia/enums'
import { prettifyPath } from '@/plugins/helper'
import { isMiddlewareSkipped } from '@/utils/helpers'
import { removeAmpSuffix } from '@fmpedia/helpers'
import { checkIfMiddlewareShouldBeSkipped } from '@/middleware/helpers'

export async function getSeoRedirectSettings({ from, route, ctx }) {
  console.time(`seo-redirects - ${route.path}`)
  const isSeoRedirectsSkipped =
    isMiddlewareSkipped(MIDDLEWARE.SEO_REDIRECT, route) ||
    checkIfMiddlewareShouldBeSkipped(from, route)

  if (route.query.previewId || isSeoRedirectsSkipped) {
    return null
  }

  try {
    const queryParams = {
      from: pipe(prettifyPath, removeAmpSuffix)(route.path)
    }
    const { Type, Value } = await ctx.store.dispatch('seo/requestRedirect', {
      queryParams
    })

    if (Type && Value) {
      return {
        type: REDIRECT_TYPE_CODES[Type],
        to: Value
      }
    }
  } catch (err) {
    console.error('err in seo-redirects: ', err)
    return null
  }
  console.timeEnd(`seo-redirects - ${route.path}`)
}
