import { ROUTE_NAME } from 'enums/routes'
import { removeEndingSlashes } from '@fmpedia/helpers'

export const GROUP_TYPE = {
  STATIC: 'Static',
  ARTICLE: 'Article',
  CATEGORY: 'Category',
  QIR: 'Qir',
  TAG: 'Tag',
  TERM: 'Term',
  AUTHOR: 'Author'
}

export const META_PARAM = {
  HOMEPAGE: 'Homepage'
}

export const GROUP_TYPES_LIST = Object.values(GROUP_TYPE)

export const GROUP_TYPES_BY_ROUTE_NAME = {
  /** ARTICLE **/
  [ROUTE_NAME.CATEGORY_SUBCATEGORY_ARTICLE]: [GROUP_TYPE.ARTICLE],
  [ROUTE_NAME.CATEGORY_SUBCATEGORY_ARTICLE_AMP]: [GROUP_TYPE.ARTICLE],
  [ROUTE_NAME.CATEGORY_EDUCATION_CENTER_ARTICLE]: [GROUP_TYPE.ARTICLE],
  [ROUTE_NAME.CATEGORY_EDUCATION_CENTER_ARTICLE_AMP]: [GROUP_TYPE.ARTICLE],
  /** ARTICLE OR CATEGORY **/
  [ROUTE_NAME.CATEGORY_SUBCATEGORY]: [GROUP_TYPE.CATEGORY, GROUP_TYPE.ARTICLE],
  /** CATEGORY **/
  [ROUTE_NAME.CATEGORY]: [GROUP_TYPE.CATEGORY],
  [ROUTE_NAME.CATEGORY_EDUCATION_CENTER]: [GROUP_TYPE.CATEGORY],
  /** QIR **/
  [ROUTE_NAME.INTELLIGENCE_PRODUCTS_QIR_SLUG]: [GROUP_TYPE.QIR],
  /** TERM **/
  [ROUTE_NAME.TERMS_LETTER_SLUG]: [GROUP_TYPE.TERM],
  /** AUTHOR **/
  [ROUTE_NAME.AUTHOR_SLUG]: [GROUP_TYPE.AUTHOR],
  [ROUTE_NAME.TAG_SLUG]: [GROUP_TYPE.TAG]
}

export const getGroupTypesByPageName = pageName => {
  return GROUP_TYPES_BY_ROUTE_NAME[pageName] || [GROUP_TYPE.STATIC]
}

export const NO_DIRECTIVE = 'Nodirectives'

export const TEMPLATE_PARAM = {
  [GROUP_TYPE.STATIC]: {
    META_TITLE: 'PAGE_NAME',
    META_DESCRIPTION: 'PAGE_NAME'
  },
  [GROUP_TYPE.ARTICLE]: {
    META_TITLE: 'ARTICLE_TITLE',
    META_DESCRIPTION: 'ARTICLE_BRIEF'
  },
  [GROUP_TYPE.CATEGORY]: {
    META_TITLE: 'CATEGORY_NAME',
    META_DESCRIPTION: 'CATEGORY_NAME'
  },
  [GROUP_TYPE.QIR]: {
    META_TITLE: 'QIR_NAME',
    META_DESCRIPTION: 'QIR_NAME'
  },
  [GROUP_TYPE.TAG]: {
    META_TITLE: 'TAG_NAME',
    META_DESCRIPTION: 'TAG_NAME'
  },
  [GROUP_TYPE.TERM]: {
    META_TITLE: 'TERM_NAME',
    META_DESCRIPTION: 'TERM_NAME'
  },
  [GROUP_TYPE.AUTHOR]: {
    META_TITLE: 'AUTHOR_NAME',
    META_DESCRIPTION: 'AUTHOR_NAME'
  }
}

export const TEMPLATE_PARAMS_LIST = Object.values(TEMPLATE_PARAM)

export const META_TITLE_PARAMS_REGEX = new RegExp(
  TEMPLATE_PARAMS_LIST.map(({ META_TITLE }) => META_TITLE).join('|'),
  'g'
)

export const META_DESCRIPTION_PARAMS_REGEX = new RegExp(
  TEMPLATE_PARAMS_LIST.map(({ META_DESCRIPTION }) => META_DESCRIPTION).join(
    '|'
  ),
  'g'
)

/** Needed to request SEO settings for error page **/
export const STATIC_PAGE = {
  ERROR: 'error/',
  HOMEPAGE: '/'
}

export const GROUP_TYPES_WITHOUT_ENDING_SLASH_IN_SLUG = [
  GROUP_TYPE.TAG,
  GROUP_TYPE.TERM,
  GROUP_TYPE.QIR,
  GROUP_TYPE.AUTHOR,
  GROUP_TYPE.ARTICLE
]

export function checkIfGroupTypeWithoutEndingSlash(groupType) {
  return GROUP_TYPES_WITHOUT_ENDING_SLASH_IN_SLUG.includes(groupType)
}

export function handleEndingSlash(slug, possibleGroupTypes) {
  const isSlugWithoutEndingSlash = possibleGroupTypes.every(groupType =>
    checkIfGroupTypeWithoutEndingSlash(groupType)
  )

  return `${removeEndingSlashes(slug)}${isSlugWithoutEndingSlash ? '' : '/'}`
}
