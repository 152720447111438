import apiV2 from '~/utils/apiV2'
import https from 'https'
import axios from 'axios'
import qs from 'qs'
import { isEmpty, isNil } from 'ramda'
import { closeAllModals, getTokenValue, isString } from '@/plugins/helper'
import { GUARDED_ROUTES, ROUTE } from 'enums/routes'
import { API_VERSION, API_PATH_PREFIX_BY_API_VERSION } from 'enums/api'
import Cookies from 'js-cookie'
import { DEVICE_ID_COOKIE_NAME } from '~/server/enums'

const agent = new https.Agent({
  rejectUnauthorized: false
})

const paramsSerializer = params =>
  qs.stringify(params, {
    filter: (key, val) => {
      if (isNil(val) || isEmpty(val)) return undefined

      return val instanceof Date ? +val : val
    },
    arrayFormat: 'repeat'
  })

export default function({ $axios, req, app, redirect, store, route }, inject) {
  const generateRequestInterceptorHandler = (apiUrl, apiUrlInternal) => {
    return function(config) {
      if (!app.$helper.checkIfRefreshTokenIsRelevant()) {
        /**
         * We check if there are two or more users logged in simultaneously!
         * In case they are - we refresh the page for user with old token.
         */
        location.reload()
        return false
      }

      config.progress = false

      if (process.client) {
        config.headers.DeviceId = Cookies.get(DEVICE_ID_COOKIE_NAME)
      }

      config.headers.Page =
        store.state.routerNavigatedToFullPath || route.fullPath

      config.baseURL = process.client ? apiUrl : apiUrlInternal || apiUrl

      const token = getTokenValue(store.getters['auth/accessToken'])
      if (token) {
        config.headers.token = token
      }

      return config
    }
  }

  const responseInterceptorErrorHandler = axiosInstance => errorReason => {
    const { response } = errorReason
    if (isAuthorizationResponseError(response)) {
      return store
        .dispatch('auth/refreshAccessToken')
        .then(() => {
          response.config.__isRequestRetry = true
          return axiosInstance.request(response.config)
        })
        .catch(() => {
          store.dispatch('auth/deauthenticateUser')
          closeAllModals()
          const {
            name: currentRouteName,
            fullPath
          } = app.router.history.current
          const redirectPath = GUARDED_ROUTES.includes(currentRouteName)
            ? ROUTE.HOMEPAGE
            : fullPath
          redirect(redirectPath)
          throw errorReason
        })
    }

    throw errorReason
  }

  $axios.setHeader('Accept-Language', 'en-US')
  $axios.defaults.httpsAgent = agent
  $axios.defaults.paramsSerializer = paramsSerializer

  const apiV1PathPrefix = API_PATH_PREFIX_BY_API_VERSION[API_VERSION.V1]
  const apiV2PathPrefix = API_PATH_PREFIX_BY_API_VERSION[API_VERSION.V2]

  $axios.interceptors.request.use(
    generateRequestInterceptorHandler(
      `${app.$env.API_URL}/${apiV1PathPrefix}`,
      `${app.$env.API_URL_INTERNAL}/${apiV1PathPrefix}`
    )
  )

  $axios.interceptors.response.use(
    r => r,
    responseInterceptorErrorHandler($axios)
  )

  const flAxios = axios.create({
    headers: {
      'Accept-Language': 'en-US'
    },
    httpsAgent: agent
  })
  flAxios.defaults.paramsSerializer = paramsSerializer

  flAxios.interceptors.request.use(
    generateRequestInterceptorHandler(
      `${app.$env.FL_API_URL}/${apiV1PathPrefix}`,
      `${app.$env.FL_API_URL_INTERNAL}/${apiV1PathPrefix}`
    )
  )

  flAxios.interceptors.response.use(
    r => r,
    responseInterceptorErrorHandler(flAxios)
  )

  const axiosV2 = axios.create({
    headers: {
      'Accept-Language': 'en-US'
    },
    httpsAgent: agent
  })
  axiosV2.defaults.paramsSerializer = paramsSerializer

  axiosV2.interceptors.request.use(
    generateRequestInterceptorHandler(
      `${app.$env.API_URL}/${apiV2PathPrefix}`,
      `${app.$env.API_URL_INTERNAL}/${apiV2PathPrefix}`
    )
  )

  axiosV2.interceptors.response.use(
    r => r,
    responseInterceptorErrorHandler(axiosV2)
  )

  inject('flAxios', flAxios)
  inject('axiosV2', axiosV2)
}

function isAuthorizationResponseError(response) {
  return (
    response &&
    response.status === 401 &&
    !response.config.url.includes(apiV2.users.refreshAccessToken()) &&
    isString(response.data) &&
    !response.config.__isRequestRetry
  )
}
