import * as types from './mutation-types'

const state = () => ({
  redirectQueue: [],
  pageLeaveConfirmRequiredItems: [],
  isPageLeaveRequested: false,
  pageLeaveRequestResolver: null
})

const getters = {
  redirectQueue: state => state.redirectQueue,
  isPageLeaveAllowed: state => state.pageLeaveConfirmRequiredItems.length === 0,
  isPageLeaveRequested: state => state.isPageLeaveRequested,
  pageLeaveConfirmRequiredItems: state => state.pageLeaveConfirmRequiredItems
}

const debug = false

function debugLog(...args) {
  if (!debug) return

  const [first, ...others] = args
  const firstArg = `store/router.js > ${first}`

  console.log(firstArg, ...others)
}

const actions = {
  saveRouteChangeRequestPromise({ commit }, { promise, fullPath }) {
    debugLog('== saveRouteChangeRequestPromise() ==')
    debugLog('fullPath: ', fullPath)
    commit(types.SET_CHANGE_ROUTE_REQUEST_PROMISE, { promise, fullPath })
  },
  saveRouteChangeRequestResolver({ commit }, { resolve, fullPath }) {
    debugLog('== saveRouteChangeRequestResolver() ==')
    debugLog('fullPath: ', fullPath)
    debugLog('resolve: ', resolve)
    commit(types.SET_CHANGE_ROUTE_REQUEST_RESOLVER, { resolve, fullPath })
  },
  addRouteToQueue({ commit, dispatch, state }, redirectTo) {
    debugLog('== router addRouteToQueue() ==')
    debugLog('redirectTo: ', redirectTo)
    const sameRouteInQueueIndex = state.redirectQueue.findIndex(
      queueItem => queueItem.fullPath === redirectTo.fullPath
    )
    const queueLength = state.redirectQueue.length
    debugLog('sameRouteInQueueIndex: ', sameRouteInQueueIndex)
    debugLog('queueLength: ', queueLength)

    if (sameRouteInQueueIndex === -1) {
      debugLog('There is no route with the same fullPath in the queue.')
      commit(types.ADD_ROUTE_TO_QUEUE, redirectTo)
      return
    }

    debugLog('Found the same fullPath existing in the queue.')
    const queueItemsToRemove = state.redirectQueue.slice(
      sameRouteInQueueIndex,
      queueLength
    )
    debugLog('queueItemsToRemove: ', queueItemsToRemove)
    queueItemsToRemove.forEach(item => {
      debugLog('item.fullPath: ', item.fullPath)
      commit(types.REMOVE_ROUTE_FROM_QUEUE, item.fullPath)
    })
    commit(types.ADD_ROUTE_TO_QUEUE, redirectTo)
  },
  removeRouteFromQueue({ commit, dispatch, state }, fullPath) {
    debugLog('== removeRouteFromQueue() ==')
    debugLog('fullPath: ', fullPath)
    debugLog('before remove')
    state.redirectQueue.forEach((redirect, index) => {
      debugLog(`>> redirect from queue [${index}]: `, redirect.fullPath)
    })

    commit(types.REMOVE_ROUTE_FROM_QUEUE, fullPath)
    debugLog('after remove')
    state.redirectQueue.forEach((redirect, index) => {
      debugLog(`>> redirect from queue [${index}]: `, redirect.fullPath)
    })

    debugLog('state.redirectQueue.length: ', state.redirectQueue.length)
    if (state.redirectQueue.length) {
      const firstInQueue = state.redirectQueue[0]
      debugLog('firstInQueue: ', firstInQueue)

      if (firstInQueue.resolve) {
        debugLog('resolve promise')
        firstInQueue.resolve()
      }
    } else {
      debugLog('STOP navigation if the queue is empty!!!')
      commit(types.SET_PAGE_NAVIGATION_IN_PROGRESS_STATE, false, { root: true })
    }
  },
  async removeAllNavigationExcept(
    { commit, dispatch, state },
    fullPathDoNotRemove
  ) {
    debugLog(`== removeAllNavigationExcept(${fullPathDoNotRemove}) ==`)
    state.redirectQueue
      .filter(({ fullPath }) => fullPath !== fullPathDoNotRemove)
      .forEach(({ fullPath }) => {
        dispatch('removeRouteFromQueue', fullPath)
      })
  },
  async routeIsTheFirstInQueuePromise({ commit, dispatch, state }, fullPath) {
    debugLog('== routeQueueIsOnlyThisFullPath() ==')
    const promise = new Promise(resolve => {
      dispatch('saveRouteChangeRequestResolver', { resolve, fullPath })
    })
    await dispatch('saveRouteChangeRequestPromise', { promise, fullPath })

    return promise
  },
  disablePageLeave({ commit }, item) {
    commit(types.ADD_ITEM_THAT_REQUIRES_CONFIRM, item)
  },
  enablePageLeaveById({ commit }, id) {
    commit(types.REMOVE_ITEM_THAT_REQUIRES_CONFIRM, id)
  },
  async getIdsWithPageLeaveRequiredConfirm({ getters }) {
    const items = getters.pageLeaveConfirmRequiredItems

    const results = await Promise.all(
      items.map(async item => {
        const isConfirmRequired = item.isConfirmRequiredFn
          ? await item.isConfirmRequiredFn()
          : true

        return { ...item, isConfirmRequired }
      })
    )

    return results
      .filter(({ isConfirmRequired }) => isConfirmRequired)
      .map(item => item.id)
  },
  requestPageLeave({ commit }) {
    commit(types.SET_IS_PAGE_LEAVE_REQUESTED, true)
  },
  cancelPageLeave({ commit }) {
    commit(types.SET_IS_PAGE_LEAVE_REQUESTED, false)
  },
  savePageLeaveRequestResolver({ commit }, resolver) {
    commit(types.SET_PAGE_LEAVE_REQUEST_RESOLVER, resolver)
  },
  submitPageLeave({ state, dispatch }) {
    if (!state.pageLeaveRequestResolver) return

    state.pageLeaveRequestResolver(true)
    dispatch('cancelPageLeave')
    dispatch('savePageLeaveRequestResolver', null)
  },
  denyPageLeave({ dispatch, state }) {
    if (!state.pageLeaveRequestResolver) return

    state.pageLeaveRequestResolver(false)
    dispatch('cancelPageLeave')
    dispatch('savePageLeaveRequestResolver', null)
  },
  async concurrentRouterPush(
    { dispatch, state },
    { router, to, replace = false }
  ) {
    function successCallback() {}
    async function errorCallback({ to, from }) {
      debugLog('== errorCallback() ==')
      debugLog('to.fullPath: ', to.fullPath)
      dispatch('removeRouteFromQueue', to.fullPath)
    }

    if (replace) {
      router.replace(to, successCallback, errorCallback)
    } else {
      router.push(to, successCallback, errorCallback)
    }
  }
}

const mutations = {
  [types.SET_CHANGE_ROUTE_REQUEST_PROMISE](state, { promise, fullPath }) {
    debugLog('== SET_CHANGE_ROUTE_REQUEST_PROMISE() ==')
    const queueItem = state.redirectQueue.find(
      item => item.fullPath === fullPath
    )

    if (!queueItem) return

    queueItem.promise = promise
  },
  [types.SET_CHANGE_ROUTE_REQUEST_RESOLVER](state, { resolve, fullPath }) {
    debugLog('== SET_CHANGE_ROUTE_REQUEST_RESOLVER() ==')
    const queueItem = state.redirectQueue.find(
      item => item.fullPath === fullPath
    )

    if (!queueItem) return

    queueItem.resolve = resolve
  },
  [types.ADD_ROUTE_TO_QUEUE](state, data) {
    state.redirectQueue.push(data)
  },
  [types.REMOVE_ROUTE_FROM_QUEUE](state, fullPath) {
    debugLog('== REMOVE_ROUTE_FROM_QUEUE() ==')
    debugLog('fullPath: ', fullPath)
    let isRouteRemoved = false
    state.redirectQueue = state.redirectQueue.filter(item => {
      debugLog('item: ', item)
      debugLog('isRouteRemoved: ', isRouteRemoved)

      if (isRouteRemoved) return true

      debugLog('item.fullPath: ', item.fullPath)
      debugLog('fullPath: ', fullPath)
      if (item.fullPath === fullPath) {
        if (item.resolve) {
          debugLog('resolve promise from the mutation')
          item.resolve()
        }
        isRouteRemoved = true
        return false
      }

      return true
    })
  },
  [types.ADD_ITEM_THAT_REQUIRES_CONFIRM](state, item) {
    if (
      !state.pageLeaveConfirmRequiredItems.map(({ id }) => id).includes(item.id)
    ) {
      state.pageLeaveConfirmRequiredItems = [
        ...state.pageLeaveConfirmRequiredItems,
        item
      ]
    }
  },
  [types.REMOVE_ITEM_THAT_REQUIRES_CONFIRM](state, id) {
    if (state.pageLeaveConfirmRequiredItems.map(({ id }) => id).includes(id)) {
      state.pageLeaveConfirmRequiredItems = state.pageLeaveConfirmRequiredItems.filter(
        ({ id: existingId }) => existingId !== id
      )
    }
  },
  [types.SET_IS_PAGE_LEAVE_REQUESTED](state, data) {
    state.isPageLeaveRequested = data
  },
  [types.SET_PAGE_LEAVE_REQUEST_RESOLVER](state, data) {
    state.pageLeaveRequestResolver = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
