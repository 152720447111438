/** Previously we imported variables from scss files, but it leads to the issue described bolow:  **/
/**
 WARNING: Do not use :export {} inside file, which you pass to nuxt-style-resources module !!!!!
 ------------------------------------
 Reason: We use nuxt-style-resources module, which allows us to use variables/mixins inside component,
 without need to define 'import' in component style.
 If you use :export {} inside files, which are passed to nuxt-style-resources module -  this leads
 to situation that all variables/mixins duplicated to each component with scoped style.
 **/

export const breakpointNames = {
  mobile: 'mobile',
  tablet: 'tablet',
  desktopSm: 'desktopSm',
  desktopMd: 'desktopMd',
  desktopLg: 'desktopLg',
  desktopXl: 'desktopXl',
  aboveDesktopXl: 'aboveDesktopXl'
}

const breakpointValues = {
  [breakpointNames.mobile]: 767,
  [breakpointNames.tablet]: 1023,
  [breakpointNames.desktopSm]: 1199,
  [breakpointNames.desktopMd]: 1399,
  [breakpointNames.desktopLg]: 1599,
  [breakpointNames.desktopXl]: 1919,
  [breakpointNames.aboveDesktopXl]: Infinity
}

const colors = {
  gray: '#727272',
  grayLight: '#9b9b9b',
  grayDark: '#221e20',
  white: '#fff',
  main: '#69f',
  mobileSearchBtn: '#173ec2',
  error: '#f56123',
  email: 'gray',
  followTag: '#313030',
  save: '#ff910e',
  footer: '#ededed',
  notificationCount: '#3cf',
  twitter: '#0099d3',
  facebook: '#3b5998',
  linkedin: '#0073b1',
  whatsapp: '#2cb742',
  multiselectTextColor: '#626262',
  eventsSocial: '#464646'
}

export default {
  breakpoints: breakpointValues,
  breakpointNames,
  colors
}

export const BREAKPOINT_RENDER_ORDERING = [
  breakpointNames.aboveDesktopXl,
  breakpointNames.desktopXl,
  breakpointNames.desktopLg,
  breakpointNames.desktopMd,
  breakpointNames.desktopSm,
  breakpointNames.tablet,
  breakpointNames.mobile
]

export class Breakpoint {
  constructor({ name, value }) {
    if (!name && !value) {
      throw new Error(
        'Please provide either name or value to create a Breakpoint instance'
      )
    }
    this._name = null
    this._value = null

    if (name) {
      this._createFromName(name)
    } else if (value) {
      this._createFromValue(value)
    }
  }
  get name() {
    return this._name
  }
  get value() {
    return this._value
  }
  _createFromValue(value) {
    this._value = value
    this._name = Object.entries(breakpointValues).find(
      ([_, breakpointValue]) => value === breakpointValue
    )[0]
  }
  _createFromName(name) {
    this._name = name
    this._value = breakpointValues[name]
  }
  _getPreviousBreakpointName() {
    const currentIndex = BREAKPOINT_RENDER_ORDERING.indexOf(this._name)
    if (currentIndex === 0) return null

    return BREAKPOINT_RENDER_ORDERING[currentIndex - 1]
  }
  _getNextBreakpointName() {
    const currentIndex = BREAKPOINT_RENDER_ORDERING.indexOf(this._name)
    if (currentIndex === BREAKPOINT_RENDER_ORDERING.length - 1) return null

    return BREAKPOINT_RENDER_ORDERING[currentIndex + 1]
  }
  get next() {
    const nextBreakpointName = this._getNextBreakpointName()
    if (!nextBreakpointName) return null

    return new Breakpoint({ name: nextBreakpointName })
  }
  get previous() {
    const previousBreakpointName = this._getPreviousBreakpointName()
    if (!previousBreakpointName) return null

    return new Breakpoint({ name: previousBreakpointName })
  }
  get isLowest() {
    return (
      this._name ===
      BREAKPOINT_RENDER_ORDERING[BREAKPOINT_RENDER_ORDERING.length - 1]
    )
  }
  get isHighest() {
    return this._name === BREAKPOINT_RENDER_ORDERING[0]
  }
  static get names() {
    return breakpointNames
  }
  static get valueByName() {
    return breakpointValues
  }
  static get values() {
    return Object.values(breakpointValues)
  }
  static get breakpointNamesByRenderOrdering() {
    return BREAKPOINT_RENDER_ORDERING
  }
  static getValueByName(name) {
    return breakpointValues[name]
  }
}

/**
 * Keep it synced with assets/scss/global/mixins/_layout.scss - $content-padding
 */
export const CONTENT_PADDING = {
  TOP: {
    aboveDesktopXl: 40,
    tablet: 30,
    mobile: 10
  },
  RIGHT: {
    aboveDesktopXl: 25,
    desktopXl: 8,
    desktopLg: 13,
    desktopMd: 9,
    tablet: 15,
    mobile: 10
  },
  BOTTOM: {
    aboveDesktopXl: 40,
    tablet: 20
  },
  LEFT: {
    aboveDesktopXl: 70,
    desktopLg: 30,
    desktopMd: 15,
    mobile: 10
  }
}
