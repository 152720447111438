const middleware = {}

middleware['301-redirect'] = require('../middleware/301-redirect.js')
middleware['301-redirect'] = middleware['301-redirect'].default || middleware['301-redirect']

middleware['410-redirect'] = require('../middleware/410-redirect.js')
middleware['410-redirect'] = middleware['410-redirect'].default || middleware['410-redirect']

middleware['content-refresh-in-background'] = require('../middleware/content-refresh-in-background.js')
middleware['content-refresh-in-background'] = middleware['content-refresh-in-background'].default || middleware['content-refresh-in-background']

middleware['contributor-without-details'] = require('../middleware/contributor-without-details.js')
middleware['contributor-without-details'] = middleware['contributor-without-details'].default || middleware['contributor-without-details']

middleware['helpers'] = require('../middleware/helpers.js')
middleware['helpers'] = middleware['helpers'].default || middleware['helpers']

middleware['path-replace'] = require('../middleware/path-replace.js')
middleware['path-replace'] = middleware['path-replace'].default || middleware['path-replace']

middleware['prevent-routing'] = require('../middleware/prevent-routing.js')
middleware['prevent-routing'] = middleware['prevent-routing'].default || middleware['prevent-routing']

middleware['remove-local-one-trust-handlers'] = require('../middleware/remove-local-one-trust-handlers.js')
middleware['remove-local-one-trust-handlers'] = middleware['remove-local-one-trust-handlers'].default || middleware['remove-local-one-trust-handlers']

middleware['router-navigation-details'] = require('../middleware/router-navigation-details.js')
middleware['router-navigation-details'] = middleware['router-navigation-details'].default || middleware['router-navigation-details']

export default middleware
