import {
  getImageSuffixFromUrlRegexp,
  isImageUrlHasSizeSuffixes
} from '@/plugins/helper'
import { CONTENT_PADDING } from '@/utils/scss'

export const IMAGE_BASE_64_REGEXP = /^data:image\/.+;base64,/

export const IMAGE_SIZE = {
  ORIGINAL: 'original',
  WIDTH_50: 'WIDTH_50',
  WIDTH_150: 'WIDTH_150',
  WIDTH_260: 'WIDTH_260',
  WIDTH_360: 'WIDTH_360',
  WIDTH_500: 'WIDTH_500',
  WIDTH_775: 'WIDTH_775',
  WIDTH_900: 'WIDTH_900',
  WIDTH_975: 'WIDTH_975'
}

export const IMAGE_SIZE_VALUE = {
  [IMAGE_SIZE.WIDTH_50]: 50,
  [IMAGE_SIZE.WIDTH_150]: 150,
  [IMAGE_SIZE.WIDTH_260]: 260,
  [IMAGE_SIZE.WIDTH_360]: 360,
  [IMAGE_SIZE.WIDTH_500]: 500,
  [IMAGE_SIZE.WIDTH_775]: 775,
  [IMAGE_SIZE.WIDTH_900]: 900,
  [IMAGE_SIZE.WIDTH_975]: 975
}

export const IMAGE_SUFFIX = {
  [IMAGE_SIZE.ORIGINAL]: '_original.',
  [IMAGE_SIZE.WIDTH_50]: '_size50.',
  [IMAGE_SIZE.WIDTH_150]: '_size150.',
  [IMAGE_SIZE.WIDTH_260]: '_size260.',
  [IMAGE_SIZE.WIDTH_360]: '_size360.',
  [IMAGE_SIZE.WIDTH_500]: '_size500.',
  [IMAGE_SIZE.WIDTH_775]: '_size775.',
  [IMAGE_SIZE.WIDTH_900]: '_size900.',
  [IMAGE_SIZE.WIDTH_975]: '_size975.'
}

export const BREAKPOINT_RANGE = {
  [IMAGE_SIZE.WIDTH_50]: [0, 50],
  [IMAGE_SIZE.WIDTH_150]: [51, 150],
  [IMAGE_SIZE.WIDTH_260]: [151, 260],
  [IMAGE_SIZE.WIDTH_360]: [261, 360],
  [IMAGE_SIZE.WIDTH_500]: [361, 500],
  [IMAGE_SIZE.WIDTH_775]: [501, 775],
  [IMAGE_SIZE.WIDTH_900]: [776, 900],
  [IMAGE_SIZE.WIDTH_975]: [901, 975],
  [IMAGE_SIZE.ORIGINAL]: [976, Infinity]
}

export const OVER_CONTENT_IMAGE_WIDTH = 2000

export const SRCSET_LOCATION = {
  ARTICLE_PAGE: 'ARTICLE_PAGE',
  AMP_ARTICLE_PAGE: 'AMP_ARTICLE_PAGE',
  HOMEPAGE_TOP_ARTICLE: 'HOMEPAGE_TOP_ARTICLE',
  HOMEPAGE_LATEST_NEWS: 'HOMEPAGE_LATEST_NEWS',
  AUTHOR_PAGE: 'AUTHOR_PAGE'
}

function getExtension(path) {
  if (!path) return ''

  return path.slice(path.lastIndexOf('.') + 1)
}

function generateSrcsetBreakpointData(imageSize, extension) {
  const width = BREAKPOINT_RANGE[imageSize][1]

  return {
    width,
    srcsetBaseLine: `${IMAGE_SUFFIX[imageSize]}${extension} ${width}w`
  }
}

function generateSrcsetDataForAllBreakpoints(url) {
  const extension = getExtension(url)
  return [
    generateSrcsetBreakpointData(IMAGE_SIZE.WIDTH_50, extension),
    generateSrcsetBreakpointData(IMAGE_SIZE.WIDTH_150, extension),
    generateSrcsetBreakpointData(IMAGE_SIZE.WIDTH_260, extension),
    generateSrcsetBreakpointData(IMAGE_SIZE.WIDTH_360, extension),
    generateSrcsetBreakpointData(IMAGE_SIZE.WIDTH_500, extension),
    generateSrcsetBreakpointData(IMAGE_SIZE.WIDTH_775, extension),
    generateSrcsetBreakpointData(IMAGE_SIZE.WIDTH_900, extension),
    generateSrcsetBreakpointData(IMAGE_SIZE.WIDTH_975, extension)
  ]
}

const HOMEPAGE_ARTICLE_SIZES_TABLET = {
  paddings: CONTENT_PADDING.LEFT.desktopMd + CONTENT_PADDING.RIGHT.tablet,
  columnGap: 20,
  get allExceptContent() {
    return this.paddings + this.columnGap
  },
  get value() {
    return `calc((100vw - ${this.allExceptContent}px) / 2)`
  }
}

const HOMEPAGE_ARTICLE_SIZES_MOBILE = {
  paddings: CONTENT_PADDING.LEFT.mobile + CONTENT_PADDING.RIGHT.mobile,
  get value() {
    return `calc(100vw - ${this.paddings}px)`
  }
}

const ARTICLE_PAGE_SIZES_TABLET = {
  paddings: CONTENT_PADDING.LEFT.desktopMd + CONTENT_PADDING.RIGHT.tablet,
  get value() {
    return `calc(100vw - ${this.paddings}px)`
  }
}

const ARTICLE_PAGE_SIZES_MOBILE = {
  paddings: CONTENT_PADDING.LEFT.mobile + CONTENT_PADDING.RIGHT.mobile,
  get value() {
    return `calc(100vw - ${this.paddings}px)`
  }
}

const SIZE_DATA_BY_SRCSET_LOCATION = {
  [SRCSET_LOCATION.HOMEPAGE_TOP_ARTICLE]: [
    { minWidth: 1920, value: '500px' },
    { minWidth: 1434, maxWidth: 1919, value: '775px' },
    { minWidth: 1024, maxWidth: 1433, value: '500px' },
    {
      maxWidth: 1023,
      minWidth: 768,
      value: HOMEPAGE_ARTICLE_SIZES_TABLET.value
    },
    { maxWidth: 767, value: HOMEPAGE_ARTICLE_SIZES_MOBILE.value },
    { value: '775px' }
  ],
  [SRCSET_LOCATION.HOMEPAGE_LATEST_NEWS]: [
    { minWidth: 1789, value: '360px' },
    { minWidth: 1600, maxWidth: 1788, value: '260px' },
    { minWidth: 1574, maxWidth: 1599, value: '500px' },
    { minWidth: 1299, maxWidth: 1573, value: '360px' },
    { minWidth: 1200, maxWidth: 1298, value: '260px' },
    { minWidth: 1119, maxWidth: 1199, value: '360px' },
    { minWidth: 1024, maxWidth: 1118, value: '260px' },
    {
      minWidth: 768,
      maxWidth: 1023,
      value: HOMEPAGE_ARTICLE_SIZES_TABLET.value
    },
    { maxWidth: 767, value: HOMEPAGE_ARTICLE_SIZES_MOBILE.value },
    { value: '737px' }
  ],
  [SRCSET_LOCATION.AMP_ARTICLE_PAGE]: [
    { minWidth: 800, value: '770px' },
    { minWidth: 799, maxWidth: 768, value: ARTICLE_PAGE_SIZES_TABLET.value },
    { maxWidth: 767, value: ARTICLE_PAGE_SIZES_MOBILE.value },
    { value: '770px' }
  ],
  [SRCSET_LOCATION.ARTICLE_PAGE]: [
    { minWidth: 1877, value: '975px' },
    { minWidth: 1752, maxWidth: 1851, value: '900px' },
    { minWidth: 1442, maxWidth: 1726, value: '775px' },
    { minWidth: 1400, maxWidth: 1416, value: '500px' },
    { minWidth: 1310, maxWidth: 1399, value: '975px' },
    { minWidth: 1200, maxWidth: 1309, value: '900px' },
    { minWidth: 1130, maxWidth: 1199, value: '975px' },
    { minWidth: 1024, maxWidth: 1129, value: '900px' },
    { minWidth: 768, maxWidth: 1023, value: ARTICLE_PAGE_SIZES_TABLET.value },
    { maxWidth: 767, value: ARTICLE_PAGE_SIZES_MOBILE.value },
    { value: '900px' }
  ],
  [SRCSET_LOCATION.AUTHOR_PAGE]: [
    { minWidth: 768, value: '320px' },
    { maxWidth: 767, value: '200px' },
    { value: '320px' }
  ]
}

const IMAGE_PLACEHOLDER_SIZE = IMAGE_SIZE.WIDTH_50
const IMAGE_PLACEHOLDER_WIDTH = BREAKPOINT_RANGE[IMAGE_PLACEHOLDER_SIZE][1]

export class Srcset {
  constructor(settings) {
    const {
      originalUrl,
      srcsetLocation,
      maxWidth,
      isImagePlaceholder
    } = settings
    this._originalUrl = originalUrl || null
    this._srcsetBreakpointData = generateSrcsetDataForAllBreakpoints(
      this._originalUrl
    )
    this._srcsetLocation = srcsetLocation
    this._maxWidth = maxWidth || null
    this._isImagePlaceholder = isImagePlaceholder || false
  }
  get sizeData() {
    if (!this._srcsetLocation) return null

    return SIZE_DATA_BY_SRCSET_LOCATION[this._srcsetLocation]
  }
  get maxImageBreakpoint() {
    if (!this._maxWidth) return null

    const breakpoints = this._srcsetBreakpointData.map(({ width }) => width)

    return breakpoints
      .sort((a, b) => a - b)
      .find(breakpoint => this._maxWidth <= breakpoint)
  }
  get extension() {
    return getExtension(this._originalUrl)
  }
  getBinaryStringFromBooleanArray(arr) {
    return arr.reduce((acc, value) => acc + +!!value, '')
  }
  generateSrcsetLine() {
    if (!this._originalUrl) return null

    const maxImageBreakpoint = this._isImagePlaceholder
      ? IMAGE_PLACEHOLDER_WIDTH
      : this.maxImageBreakpoint

    const srcsetBaseLines = maxImageBreakpoint
      ? this._srcsetBreakpointData
          .filter(({ width }) => width <= maxImageBreakpoint)
          .map(({ srcsetBaseLine }) => srcsetBaseLine)
      : this._srcsetBreakpointData.map(({ srcsetBaseLine }) => srcsetBaseLine)

    return srcsetBaseLines
      .map(baseLine => `${this.mainUrlPart}${baseLine}`)
      .join(', ')
  }
  get mainUrlPart() {
    const regexp = getImageSuffixFromUrlRegexp(
      IMAGE_SUFFIX[IMAGE_SIZE.ORIGINAL]
    )
    return this._originalUrl.replace(regexp, '$1')
  }
  generateSizesLine() {
    if (!this.sizeData) return null

    return this.sizeData
      .map(({ minWidth, maxWidth, value }) => {
        if (!minWidth && !maxWidth) return ''

        const minWidthLine = minWidth ? `(min-width: ${minWidth}px)` : null
        const maxWidthLine = maxWidth ? `(max-width: ${maxWidth}px)` : null
        const boolString = this.getBinaryStringFromBooleanArray([
          minWidthLine,
          maxWidthLine
        ])
        const resultMap = {
          '00': `${value}`,
          '01': `${maxWidthLine} ${value}`,
          '10': `${minWidthLine} ${value}`,
          '11': `(${minWidthLine} and ${maxWidthLine}) ${value}`
        }
        return resultMap[boolString]
      })
      .filter(v => !!v)
      .join(', ')
  }
  getFallbackSrc() {
    const { value } =
      this.sizeData.find(size => !size.minWidth && !size.maxWidth) || {}

    if (!value) return this._originalUrl

    const fallbackValue = parseInt(value, 10)
    const imageSize = Object.keys(IMAGE_SIZE_VALUE).find(
      key => IMAGE_SIZE_VALUE[key] === fallbackValue
    )

    if (!imageSize) return this._originalUrl

    return `${this.mainUrlPart}${IMAGE_SUFFIX[imageSize]}${this.extension}`
  }
}

export function getImagePreloadLink({ url, location }) {
  if (!url) return null

  if (isImageUrlHasSizeSuffixes(url) && location) {
    const srcset = new Srcset({ originalUrl: url, srcsetLocation: location })

    return {
      rel: 'preload',
      as: 'image',
      imagesrcset: srcset.generateSrcsetLine(),
      imagesizes: srcset.generateSizesLine()
    }
  }

  return {
    rel: 'preload',
    as: 'image',
    href: url
  }
}
