import { applyFallback } from './applyFallback'
import { stringtoKebabCase } from './stringtoKebabCase'
import { isMiddlewareSkipped } from './isMiddlewareSkipped'

export { applyFallback, stringtoKebabCase, isMiddlewareSkipped }

export function guid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export async function getImageDimensions({ file, url = '' }) {
  if (!isFile(file) && !url) return null

  return new Promise(resolve => {
    const img = new Image()

    img.src = isFile(file) ? window.URL.createObjectURL(file) : url
    img.onload = function() {
      resolve({
        width: this.naturalWidth,
        height: this.naturalHeight
      })
    }
  })
}

export function isFile(file) {
  if (!file) return false

  const isObjectFile =
    typeof file === 'object' &&
    (file.constructor === Blob || file.constructor === File)
  const isStringFile = typeof file === 'string' && file.slice(0, 5) === 'blob:'

  return isObjectFile || isStringFile
}

export function getFileExtension(filename) {
  if (!filename) return null

  return (
    filename.substring(filename.lastIndexOf('.'), filename.length) || filename
  ).toLowerCase()
}

export function checkIfLinkHasProtocol(url) {
  if (!url) return false

  return /https?:\/\//.test(url)
}
