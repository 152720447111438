import {
  getBackendErrorCode,
  getBackendErrorEvidence
} from '../../server/helper'
import { USER_STATUS } from './users'

export const DEFAULT_404_ERROR_SHORTCODE = '#defaultMessage'

export const ERROR_HANDLER = {
  OPEN_SIGN_IN_WINDOW: 'OPEN_SIGN_IN_WINDOW'
}
/*
 * names of HTTP response status codes/names were taken from this link
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
 * */

export const HTTP_STATUS_CODE = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  GONE: 410,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503
}

export const ERROR_MESSAGE = {
  PAGE_NOT_FOUND:
    "404 Error. It could be an outdated link or just a small technical glitch. Try the URL again, or find what you're looking for on our <a-link to='/'>Homepage</a-link>.",
  DEFAULT_SERVER_ERROR:
    'The server is experiencing an outage, either planned or unplanned'
}

export const ERROR_TITLE = {
  PAGE_NOT_FOUND: 'page not found'
}

/**
 * In case of redirecting user to error page we always show 404 status
 * and message "Page not found" (requested by client)
 * Commented message and status are real mapping (could be used later)
 */
const DEFAULT_INFO_SETTINGS = {
  title: ERROR_TITLE.PAGE_NOT_FOUND,
  message: ERROR_MESSAGE.PAGE_NOT_FOUND,
  status: HTTP_STATUS_CODE.NOT_FOUND
}

const HTTP_STATUS_INFO_SETTINGS = {
  [HTTP_STATUS_CODE.BAD_REQUEST]: {
    // title: 'Service unavailable',
    // message: ERROR_MESSAGE.DEFAULT_SERVER_ERROR,
    // status: HTTP_STATUS_CODE.SERVICE_UNAVAILABLE
    ...DEFAULT_INFO_SETTINGS
  },
  [HTTP_STATUS_CODE.FORBIDDEN]: {
    // title: 'Forbidden',
    // message: 'You do not have access to this page',
    // status: HTTP_STATUS_CODE.FORBIDDEN
    ...DEFAULT_INFO_SETTINGS
  },
  [HTTP_STATUS_CODE.NOT_FOUND]: {
    // title: ERROR_MESSAGE.PAGE_NOT_FOUND,
    // message:
    //   "404 Error. It could be an outdated link or just a small technical glitch. Try the URL again, or find what you're looking for on our <a href='/'>Homepage</a>.",
    // status: HTTP_STATUS_CODE.NOT_FOUND
    ...DEFAULT_INFO_SETTINGS
  },
  [HTTP_STATUS_CODE.METHOD_NOT_ALLOWED]: {
    // title: ERROR_MESSAGE.PAGE_NOT_FOUND,
    // message:
    //   "405 Error. It could be an outdated link or just a small technical glitch. Try the URL again, or find what you're looking for on our <a href='/'>Homepage</a>.",
    // status: HTTP_STATUS_CODE.METHOD_NOT_ALLOWED
    ...DEFAULT_INFO_SETTINGS
  },
  [HTTP_STATUS_CODE.GONE]: {
    title: 'Page deleted or gone',
    message:
      'The page you are looking for may have been deleted, please visit our <a href="/">homepage</a> for the latest info.',
    status: HTTP_STATUS_CODE.GONE
  },
  [HTTP_STATUS_CODE.INTERNAL_SERVER_ERROR]: {
    // title: 'Internal Server Error',
    // message: ERROR_MESSAGE.DEFAULT_SERVER_ERROR,
    // status: HTTP_STATUS_CODE.SERVICE_UNAVAILABLE
    ...DEFAULT_INFO_SETTINGS
  },
  [HTTP_STATUS_CODE.SERVICE_UNAVAILABLE]: {
    // title: 'Internal Server Error',
    // message: ERROR_MESSAGE.DEFAULT_SERVER_ERROR,
    // status: HTTP_STATUS_CODE.SERVICE_UNAVAILABLE
    ...DEFAULT_INFO_SETTINGS
  }
}

const DEFAULT_ERROR_SETTINGS = {
  status: 404,
  showErrorPage: false,
  showMessage: true,
  skipLogging: false
}

export const ERROR_CODE = {
  ARTICLE_IS_NOT_PUBLISHED: 'ArticleIsNotPublished',
  ARTICLE_IS_NOT_PUBLISHED_UNDER_EDUCATION_CATEGORIES:
    'ArticleIsNotPublishedUnderEducationCategories',
  ARTICLE_DOES_NOT_HAVE_MAIN_CATEGORY_SELECTED:
    'ArticleDoesNotHaveMainCategorySelected',
  ARTICLE_PREVIEW_NOT_FOUND: 'ArticlePreviewNotFound',
  ARTICLE_SLUG_ALREADY_EXISTS: 'ArticleSlugAlreadyExists',
  ARTICLE_NOT_FOUND: 'ArticleNotFound',
  USER_NOT_FOUND: 'UserNotFound', // => USER_NOT_FOUND
  PASSWORD_CHANGE_FAILED: 'PasswordChangeFailed', // => USER_INVALID_CREDENTIALS
  INVALID_LOGIN_CREDENTIALS: 'InvalidLoginCredentials', // => INVALID_LOGIN_CREDENTIALS
  LOGIN_ATTEMPTS_LIMIT_EXCEDEED: 'LoginAttemptsLimitExceeded',
  USER_ALREADY_EXISTS: 'UserAlreadyExists', // => EMAIL_IS_ALREADY_TAKEN
  INVALID_RELATE_ARTICLE_MODEL: 'InvalidRelateArticleModel',
  CATEGORY_NOT_FOUND: 'CategoryNotFound',
  USER_NOT_ACTIVE: 'UserNotActive',
  TERM_NOT_FOUND: 'TermNotFound',
  TAG_NOT_FOUND: 'TagNotFound',
  DEACTIVATED_USER_ACTION_FAILED: 'DeactivatedUserActionFailed',
  MIGRATED_USER_ACTION_FAILED: 'MigratedUserActionFailed',
  MIGRATED_DEACTIVATED_CONTRIBUTOR_ACTION_FAILED:
    'MigratedDeactivatedContributorActionFailed',
  USER_IS_ALREADY_ACTIVE: 'UserIsAlreadyActive', // => Used on reactivation page, replaced by combination of code + evidence
  INTERNAL_SERVER_ERROR: 'InternalServerError',
  COUNTRY_BY_IP_NOT_FOUND: 'CountryByIpNotFound',
  QIR_NOT_FOUND: 'QIRNotFound',
  POTENTIAL_SCRIPT_INJECTION: 'PotentialScriptInjection',
  DIRECTORY_ARTICLE_CONTENT_ALREADY_EXISTS:
    'DirectoryArticleContentAlreadyExists',
  QUOTA_IS_OVER: 'QuotaIsOver',
  TOKEN_HAS_EXPIRED: 'TokenHasExpired',
  DIRECTORY_ARTICLE_SLUG_ALREADY_EXISTS: 'DirectoryArticleSlugAlreadyExists',
  COMPANY_VIDEO_SLUG_ALREADY_EXISTS: 'CompanyVideoSlugAlreadyExists',
  VIDEO_NOT_FOUND: 'VideoNotFound',
  YOUTUBE_VIDEO_DOES_NOT_EXIST: 'YouTubeVideoDoesNotExist'
}

export const ERROR_CODE_V2 = {
  USER_INVALID_CREDENTIALS: 'fm.identity.00100',
  INVALID_LOGIN_CREDENTIALS: 'fm.identity.00110',
  USER_NOT_FOUND: 'fm.identity.00103',
  USER_INVALID_STATE: 'fm.identity.00101',
  UNAUTHORIZED: 'fm.api.00061',
  EMAIL_IS_ALREADY_TAKEN: 'fm.identity.00102',
  TWITTER_ACCOUNT_ALREADY_REGISTERED: 'fm.identity.00113',
  GOOGLE_ACCOUNT_ALREADY_REGISTERED: 'fm.identity.00111',
  FACEBOOK_ACCOUNT_ALREADY_REGISTERED: 'fm.identity.00112',
  VIDEO_NOT_FOUND: 'fm.content.00106',
  DRAFT_POST_NOT_FOUND: 'fm.content.00101',
  ARTICLE_SLUG_ALREADY_EXISTS: 'fm.content.00102',
  COULD_NOT_RETRIEVE_DATA_FROM_TWITTER: 'fm.api.00201',
  COULD_NOT_RETRIEVE_DATA_FROM_GOOGLE: 'fm.api.00202'
}

export const ERROR_CODE_INFO_SETTINGS_V2 = {
  [ERROR_CODE_V2.USER_INVALID_CREDENTIALS]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE_V2.USER_INVALID_CREDENTIALS,
    title: 'Invalid user credentials',
    status: 404,
    getMessage: () => 'Current password is incorrect',
    showMessage: false
  },
  [ERROR_CODE_V2.INVALID_LOGIN_CREDENTIALS]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE_V2.INVALID_LOGIN_CREDENTIALS,
    title: ERROR_MESSAGE.PAGE_NOT_FOUND,
    status: 404,
    getMessage: () => 'The Password you have entered is incorrect',
    showMessage: false
  },
  [ERROR_CODE_V2.USER_NOT_FOUND]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE_V2.USER_NOT_FOUND,
    title: ERROR_MESSAGE.PAGE_NOT_FOUND,
    status: 404,
    getMessage: () => `We could not find this account in our system.
        Please #{sign-up}{${ERROR_HANDLER.OPEN_SIGN_IN_WINDOW}}# for an account`,
    showMessage: false
  },
  [ERROR_CODE_V2.EMAIL_IS_ALREADY_TAKEN]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE_V2.EMAIL_IS_ALREADY_TAKEN,
    title: 'User already exists',
    status: 404,
    message: 'User with this email already exists',
    showMessage: false
  },
  [ERROR_CODE_V2.TWITTER_ACCOUNT_ALREADY_REGISTERED]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE_V2.TWITTER_ACCOUNT_ALREADY_REGISTERED,
    title: 'User already exists',
    status: 404,
    message: 'User with this email already exists',
    showMessage: false
  },
  [ERROR_CODE_V2.GOOGLE_ACCOUNT_ALREADY_REGISTERED]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE_V2.GOOGLE_ACCOUNT_ALREADY_REGISTERED,
    title: 'User already exists',
    status: 404,
    message: 'User with this email already exists',
    showMessage: false
  },
  [ERROR_CODE_V2.FACEBOOK_ACCOUNT_ALREADY_REGISTERED]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE_V2.FACEBOOK_ACCOUNT_ALREADY_REGISTERED,
    title: 'User already exists',
    status: 404,
    message: 'User with this email already exists',
    showMessage: false
  },
  [ERROR_CODE_V2.USER_INVALID_STATE]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE_V2.USER_INVALID_STATE,
    title: ERROR_CODE_V2.USER_INVALID_STATE,
    status: 404,
    getMessage: ({ Status }) => {
      switch (Status) {
        case USER_STATUS.DRAFT:
          return 'Please confirm your account in the confirmation email in order to activate your account'
        case USER_STATUS.ACTIVE:
          return 'User is already active'
        case USER_STATUS.DEACTIVATED:
          return 'User is deactivated'
        default:
          return 'Invalid user state'
      }
    },
    showMessage: false
  },
  [ERROR_CODE_V2.ARTICLE_SLUG_ALREADY_EXISTS]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE_V2.ARTICLE_SLUG_ALREADY_EXISTS,
    title: 'Article slug already exists',
    status: 404,
    message: 'Article with this slug already exists',
    showMessage: false
  },
  [ERROR_CODE_V2.DRAFT_POST_NOT_FOUND]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE_V2.DRAFT_POST_NOT_FOUND,
    title: 'Draft was not found',
    status: 404,
    message: 'Draft with this slug was not found',
    showMessage: false
  },
  [ERROR_CODE_V2.COULD_NOT_RETRIEVE_DATA_FROM_TWITTER]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE_V2.COULD_NOT_RETRIEVE_DATA_FROM_TWITTER,
    title: 'Could not retrieve data from Twitter',
    status: 404,
    message:
      'Could not retrieve data from Twitter. Please try to login one more time.',
    showMessage: false
  },
  [ERROR_CODE_V2.COULD_NOT_RETRIEVE_DATA_FROM_GOOGLE]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE_V2.COULD_NOT_RETRIEVE_DATA_FROM_GOOGLE,
    title: 'Could not retrieve data from Google',
    status: 404,
    message:
      'Could not retrieve data from Google. Please try to login one more time.',
    showMessage: false
  }
}

const ERROR_CODE_INFO_SETTINGS = {
  [ERROR_CODE.ARTICLE_IS_NOT_PUBLISHED]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE.ARTICLE_IS_NOT_PUBLISHED,
    title: ERROR_MESSAGE.PAGE_NOT_FOUND,
    message: ERROR_MESSAGE.PAGE_NOT_FOUND,
    showErrorPage: true,
    showMessage: false
  },
  [ERROR_CODE.YOUTUBE_VIDEO_DOES_NOT_EXIST]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE.YOUTUBE_VIDEO_DOES_NOT_EXIST,
    title: 'Video not found',
    message: 'Such YouTube video does not exist'
  },
  [ERROR_CODE.ARTICLE_IS_NOT_PUBLISHED_UNDER_EDUCATION_CATEGORIES]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE.ARTICLE_IS_NOT_PUBLISHED_UNDER_EDUCATION_CATEGORIES,
    title: ERROR_MESSAGE.PAGE_NOT_FOUND,
    message: 'Article Is Not Published Under Education Categories'
  },
  [ERROR_CODE.ARTICLE_DOES_NOT_HAVE_MAIN_CATEGORY_SELECTED]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE.ARTICLE_DOES_NOT_HAVE_MAIN_CATEGORY_SELECTED,
    title: ERROR_MESSAGE.PAGE_NOT_FOUND,
    status: 404,
    message: 'Article Does Not Have Main Category Selected'
  },
  [ERROR_CODE.ARTICLE_PREVIEW_NOT_FOUND]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE.ARTICLE_PREVIEW_NOT_FOUND,
    title: ERROR_MESSAGE.PAGE_NOT_FOUND,
    status: 404,
    message: 'Article preview not found'
  },
  [ERROR_CODE.ARTICLE_SLUG_ALREADY_EXISTS]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE.ARTICLE_SLUG_ALREADY_EXISTS,
    title: ERROR_MESSAGE.PAGE_NOT_FOUND,
    status: 404,
    message: 'Article by this title already exists. Please change article title'
  },
  [ERROR_CODE.ARTICLE_NOT_FOUND]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE.ARTICLE_NOT_FOUND,
    title: ERROR_MESSAGE.PAGE_NOT_FOUND,
    status: 404,
    message: 'page not found'
  },
  [ERROR_CODE.USER_NOT_FOUND]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE.USER_NOT_FOUND,
    title: ERROR_MESSAGE.PAGE_NOT_FOUND,
    status: 404,
    message: `We could not find this account in our system.
        Please #{sign-up}{${ERROR_HANDLER.OPEN_SIGN_IN_WINDOW}}# for an account`,
    showMessage: false
  },
  [ERROR_CODE.PASSWORD_CHANGE_FAILED]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE.PASSWORD_CHANGE_FAILED,
    title: 'Password change failed',
    status: 404,
    message: 'Current password is incorrect',
    showMessage: false
  },
  [ERROR_CODE.INVALID_LOGIN_CREDENTIALS]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE.INVALID_LOGIN_CREDENTIALS,
    title: ERROR_MESSAGE.PAGE_NOT_FOUND,
    status: 404,
    message: 'The Password you have entered is incorrect',
    showMessage: false
  },
  [ERROR_CODE.LOGIN_ATTEMPTS_LIMIT_EXCEDEED]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE.LOGIN_ATTEMPTS_LIMIT_EXCEDEED,
    title: ERROR_MESSAGE.PAGE_NOT_FOUND,
    status: 404,
    message: 'User exceeded login attempts limit. Please try to login later',
    showMessage: false
  },
  [ERROR_CODE.USER_ALREADY_EXISTS]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE.USER_ALREADY_EXISTS,
    title: 'User already exists',
    status: 404,
    message: 'User with this email already exists',
    showMessage: false
  },
  [ERROR_CODE.INVALID_RELATE_ARTICLE_MODEL]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE.INVALID_RELATE_ARTICLE_MODEL,
    title: ERROR_MESSAGE.PAGE_NOT_FOUND,
    status: 404,
    message: 'Invalid relate article model'
  },
  [ERROR_CODE.CATEGORY_NOT_FOUND]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE.CATEGORY_NOT_FOUND,
    title: ERROR_MESSAGE.PAGE_NOT_FOUND,
    status: 404,
    message:
      'Such a page is not found. Please visit our <a href="/">homepage</a> for the latest info.'
  },
  [ERROR_CODE.USER_NOT_ACTIVE]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE.USER_NOT_ACTIVE,
    title: 'User is not active!',
    status: 404,
    message:
      'Please confirm your account in the confirmation email in order to activate your account',
    showMessage: false
  },
  [ERROR_CODE.POTENTIAL_SCRIPT_INJECTION]: {
    ...DEFAULT_ERROR_SETTINGS,
    message: 'Script injections are forbidden',
    status: 404
  },
  [ERROR_CODE.TERM_NOT_FOUND]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE.TERM_NOT_FOUND,
    title: ERROR_MESSAGE.PAGE_NOT_FOUND,
    status: 404,
    message:
      'Such a page is not found. Please visit our <a href="/">homepage</a> for the latest info.',
    showErrorPage: true,
    showMessage: false
  },
  [ERROR_CODE.TAG_NOT_FOUND]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE.TAG_NOT_FOUND,
    title: ERROR_MESSAGE.PAGE_NOT_FOUND,
    status: 404,
    message: 'page not found'
  },
  [ERROR_CODE.COUNTRY_BY_IP_NOT_FOUND]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE.COUNTRY_BY_IP_NOT_FOUND,
    skip: true
  },
  [ERROR_CODE.QIR_NOT_FOUND]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE.QIR_NOT_FOUND,
    status: 404,
    message: 'page not found'
  },
  [ERROR_CODE.DIRECTORY_ARTICLE_CONTENT_ALREADY_EXISTS]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE.DIRECTORY_ARTICLE_CONTENT_ALREADY_EXISTS,
    status: 404,
    message:
      'This article consists of content that already exists. Please provide unique content'
  },
  [ERROR_CODE.DIRECTORY_ARTICLE_SLUG_ALREADY_EXISTS]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE.DIRECTORY_ARTICLE_SLUG_ALREADY_EXISTS,
    status: 404,
    message: 'This title already exists. Please choose a new one'
  },
  [ERROR_CODE.COMPANY_VIDEO_SLUG_ALREADY_EXISTS]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE.COMPANY_VIDEO_SLUG_ALREADY_EXISTS,
    status: 404,
    message: 'Video with this name was already created for this company'
  },
  [ERROR_CODE.QUOTA_IS_OVER]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE.QUOTA_IS_OVER,
    status: 404,
    message: 'Requested feature is out of quota'
  },

  // OTHERS

  [ERROR_CODE.INTERNAL_SERVER_ERROR]: {
    ...DEFAULT_ERROR_SETTINGS,
    code: ERROR_CODE.INTERNAL_SERVER_ERROR,
    title: ERROR_MESSAGE.DEFAULT_SERVER_ERROR,
    status: 404,
    message: 'page not found'
  }
}

export function getHttpStatusInfo(status) {
  return (
    HTTP_STATUS_INFO_SETTINGS[status] ||
    HTTP_STATUS_INFO_SETTINGS[HTTP_STATUS_CODE.BAD_REQUEST]
  )
}

export function getErrorSettingsByBackendError(error) {
  const code = getBackendErrorCode(error)
  const evidence = getBackendErrorEvidence(error)

  const errorInfo =
    ERROR_CODE_INFO_SETTINGS_V2[code] ||
    ERROR_CODE_INFO_SETTINGS[code] ||
    ERROR_CODE_INFO_SETTINGS.InternalServerError

  const message =
    'message' in errorInfo ? errorInfo.message : errorInfo.getMessage(evidence)

  const errorSettings = {
    ...errorInfo,
    message
  }

  return errorSettings
}
